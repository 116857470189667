html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Noto Sans CJK JP', 'Noto Sans JP', 'Inter', 'Roboto Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: 'Noto Sans CJK JP', 'Noto Sans JP', 'Inter', 'Roboto Mono';
}